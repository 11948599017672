<template>
<div class="checkout-wrapper">

     <content-loader  v-if="loaded==false"
        viewBox="0 0 520 650"
        primaryColor="#f3f3f3"
        secondaryColor="#cccccc"
      >
        <rect x="0" y="20" rx="11" ry="11" width="520" height="22" />
        <rect x="0" y="70" rx="11" ry="11" width="520" height="22" />
        <rect x="0" y="130" rx="11" ry="11" width="520" height="22" />
        <rect x="0" y="180" rx="11" ry="11" width="520" height="22" />
      </content-loader>
      <div v-else>
        <center>
          {{message}}

          <div v-if="showCheckoutLink">
            <a @click="$router.push({ name: 'checkout' })">Recommencer</a>
          </div>
        </center>
      </div>
 
     </div>
</template>

<script>


import { ContentLoader } from 'vue-content-loader'
import {Auth, APIRequest, conversionApi} from "@simb2s/senseye-sdk";
import { useCartStore } from '@/store/cartStore.js';
import { useOrderItemStore } from "@/store/orderItemStore.js";

export default {
  name: 'PaymentConfirmation',
  components: {
    ContentLoader
  },
  data() {
    return {
        useCart: useCartStore(),
        useOrderItem: useOrderItemStore(),
        loaded:false,
        stripeAPIToken: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
        stripe: '',
        message:'',
        order_id:'',
        showCheckoutLink:false,
        paimentIntentId:'',
        order:null
    }
  },
  methods:{
    async confirmPaymentFree() {
      let Parameters=this.order
      
      Parameters.status="completed"
      Parameters.testing_data=true

      delete Parameters.order_items

     
      
    
      let response=await APIRequest.update('orders',this.order.id,Parameters)
      
      await APIRequest.delete('carts',localStorage.cart_senseye_id)
      await this.useCart.resetStore()
      await this.useOrderItem.resetStore()
      localStorage.removeItem('cart_senseye_id')

      if(this.order.promotion)
      {
        let current_count=this.order.promotion.use_count
        current_count++
        let promotion=await APIRequest.detail('promotions',this.order.promotion.id)
        let params=promotion
        params.use_count=current_count

       

        if(promotion.products.length>0)
        {
         
          let products=[]
          for(let p=0;p<promotion.products.length;p++)
          {

            let item={id:promotion.products[p].id}
            products.push(item)
            
          }
          params.products=products
        }
   
        await APIRequest.update('promotions',this.order.promotion.id,params)
      }

      this.$router.push({ name: 'payment-confirmation', params: { id: this.order_id } })


    },
    async confirmPayment(){
      let Parameters={}
     
      Parameters.user=parseInt(localStorage.id_user_senseye_auth)
      Parameters.amount=this.order.amount
      Parameters.order=parseInt(this.order_id)
      Parameters.paymentintent=this.paimentIntentId
     
      let response=await APIRequest.confirmStripePayment(Parameters)
    
      if(response)
      {
        if(response.data)
        {
           if(response.data.success) {

            await APIRequest.delete('carts',localStorage.cart_senseye_id)
            localStorage.removeItem('cart_senseye_id')
            await this.useCart.resetStore()
            await this.useOrderItem.resetStore()
            
           
            this.$router.push({ name: 'payment-confirmation', params: { id: this.order_id } })
           }
           else {
             
              this.message = "Une erreur s'est produite.";
              this.showCheckoutLink=true
            }
        }
        else {
         
          this.message = "Une erreur s'est produite.";
          this.showCheckoutLink=true
        }
        
      }
      else {
       
        this.message = "Une erreur s'est produite.";
        this.showCheckoutLink=true
      }
      
    }
  },
  async mounted()
  {
    this.order_id=this.$route.params.id
    this.order=await APIRequest.detail('orders',this.$route.params.id)

    if(this.order.promotion){
      if(this.order.promotion.amount==100)
      {
        this.confirmPaymentFree()
      
        return
      }
      
    }

    this.stripe = Stripe( this.stripeAPIToken );
    const clientSecret = new URLSearchParams(window.location.search).get(
    'payment_intent_client_secret'
    );
    this.stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
    


        this.paimentIntentId=paymentIntent.id
        // Inspect the PaymentIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (paymentIntent.status) {
            case 'succeeded':
            this.message = 'Success! Payment received.';
            this.confirmPayment()
            break;

            case 'processing':
            this.message = "Payment processing. We'll update you when payment is received.";
            this.confirmPayment()
            break;

            case 'requires_payment_method':
            this.message = 'Le paiement a échoué. Veuillez recommencer avec une autre méthode de paiement.';
            this.showCheckoutLink=true
             this.loaded=true
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            break;

            default:
            this.message = "Une erreur s'est produite.";
            this.showCheckoutLink=true
            this.loaded=true
            break;
        }

        this.loaded=true
        
        });
     
  }
  
}
</script>
<style scoped>
a {
  color:#000;
}

</style>